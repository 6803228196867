export const COMMON = {};

export const ERROR = {
//请求报错
  DYNAMICFIELD_ERR: "DYNAMICFIELD_ERR",
}

// 找回密码
export const FINDPWD = {
  // 获取验证码
  GET_VERIFY: "GET_VERIFY",
  // 获取email信息
  GET_EMAIL:"GET_EMAIL",
  // 发送邮件
  SEND_EMAIL:"SEND_EMAIL",
}
