/* global window */

//获取指定hash值
export function getHashParameter(key) {
  const arr = (location.hash || "").replace(/^\#/, "").split("&");
  const params = {};
  for (let i = 0; i < arr.length; i++) {
    let data = arr[i].split("=");
    if (data.length == 2) {
      params[data[0]] = data[1];
    }
  }
  return params[key];
}

//获取cookie
export function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) {
    let value = arr[2];
    // 如果取水印的cookie，需要decodeURI
    if (name === "watermark") {
      value = decodeURIComponent(value);
    }
    return unescape(value);
  } else {
    return null;
  }
}

//判断是否为空
export function isEmpty(str) {
  if (type(str) === 'object') {
    if (str != null && Object.keys(str).length > 0) {
      return false;
    }
  } else if (type(str) !== 'array' && type(str) !== 'undefined' && type(str) !== 'error' && type(str) !== 'null') {
    str = '' + str;
    if (str.length > 0) {
      return false;
    }
  } else if (type(str) === 'array') {
    if (str.length > 0) {
      return false;
    }
  }
  return true;
}

/**
 * @获取url中参数属性
 * getUrlParam("skuId")
 */
export function getUrlParam(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = window.location.search.substr(1).match(reg);
  let h = window.location.hash.substr(3).match(reg);
  if (r !== null) return decodeURIComponent(r[2]);
  if (h !== null) return decodeURIComponent(h[2]);
  return null;
}

/**
 * localStorage封装
 */
export const storage = {
  // 根据key获取localStorage
  getItem(key) {
    let item = '';
    try {
      item = window.localStorage.getItem(key) || '';
    } catch (e) {
      return '';
    }
    return item;
  },
  // 设置localStorage
  setItem(key, value) {
    try {
      if (typeof (items) == 'object') {
        window.localStorage.setItem(key, JSON.stringify(value));
      } else {
        window.localStorage.setItem(key, value);
      }
    } catch (e) {
      return false;
    }
  },
  // 根据key删除localStorage
  removeItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      return false;
    }
  },
  // 清空localStrage
  clear() {
    try {
      window.localStorage.clear();
    } catch (e) {
      return false;
    }
  },
  /**
   * 设置cookie
   * @param {any} name  cookie键
   * @param {any} value cookie值
   * @param {any} time  时间，单位天
   */
  setCookie(name, value, time) {
    var exp = new Date();
    exp.setTime(exp.getTime() + time * 24 * 60 * 60 * 1000);
    storage.setItems(name, value, exp, '/', '.icsoc.net')
  },
  /**
   * 获取cookie
   * @export
   * @param {string} cookie键
   * @returns
   */
  getCookie(name) {
    //读取COOKIE
    storage.getItems(name)
  },
  delCookie(name) {
    storage.removeItems(name, '/', '.icsoc.net');
  },

  /*
   *  new add cookie
   */
  getItems(sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItems(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  removeItems(sKey, sPath, sDomain) {
    if (!sKey || !storage.hasItems(sKey)) {
      return false;
    }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItems(sKey) {
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys() {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  }

}