import React, { Component } from "react";

import logo from "@/static/images/logo.png";
import "./index.less";

export default class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <React.Fragment>
        <footer>
          <div className="foot">
            Copyright © 2004-{year} 中通天鸿（北京）通信科技股份有限公司
            版权所有 京ICP备12018589号
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
