import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button, message, Spin } from "antd";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MainContent from "@/components/MainContent";
import PwdContent from "./PwdContent";
import LinkInvalid from "./LinkInvalid";

import * as actions from "@/actions/resetPwd";

import "./index.less";

const FormItem = Form.Item;

@connect(
  state => ({}),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)
@Form.create()
export default class ResetPwd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      subLoading: false,
      validLink: true,
      confirmDirty: false,
      validate: false
    };

    this.timer = null;
    this.key = 1;
    this.token = props.location.query.token;

    props.actions.checkToken({ token: this.token }).then(
      res => {
        const {
          code,
          message: msg,
          data: { user_name: username, user_num: usernum } = {}
        } = res.payload;

        if (code === 2000) {
          // message.error(msg);
          this.setState({
            loading: false,
            validLink: code === 0
          });
        } else {
          this.setState({
            loading: false,
            validLink: code === 0,
            username,
            usernum
          });
        }
      },
      err => {
        this.setState({
          validLink: false,
          loading: false
        });
      }
    );
  }

  submit = e => {
    const {
      actions,
      form: { getFieldsValue, validateFields, resetFields }
    } = this.props;
    e.preventDefault();

    let values = getFieldsValue();
    if (values.password !== values.confirm) {
      this.setState(
        {
          validate: true
        },
        () => {
          validateFields(["confirm"], { force: true });
        }
      );
      return false;
    }
    validateFields((err, values) => {
      if (!err) {
        this.setState({ subLoading: true });

        actions
          .setPwd({
            token: this.token,
            new: values.password,
            confirm: values.confirm
          })
          .then(res => {
            const { code, message: msg } = res.payload;
            switch (code) {
              case 0:
                message.success(msg, 1).then(() => {
                  let origin = window.location.origin;
                  if(origin.indexOf("account-admin") != -1){
                    // 域名包含account-admin，Mange端
                    window.location.href = process.env.REACT_APP_MLOGIN_DOMAIN;
                  }else{
                    window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;
                  }
                });
                this.key++;
                resetFields();
                break;
              case 2000:
                message.error(msg, 1).then(() => {
                  let origin = window.location.origin;
                  if(origin.indexOf("account-admin") != -1){
                    // 域名包含account-admin，Mange端
                    window.location.href = process.env.REACT_APP_MLOGIN_DOMAIN;
                  }else{
                    window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;
                  }
                });
                break;
              case 9999:
                message.error("密码重置失败！");
                break;
              default:
                message.error(msg);
            }

            this.setState({ subLoading: false });
          })
          .catch(err => {
            message.error("密码重置失败！");
            this.setState({ subLoading: false });
          });
      }
    });
  };

  rtLogin = () => {
    let origin = window.location.origin;
    if(origin.indexOf("account-admin") != -1){
      // 域名包含account-admin，Mange端
      window.location.href = process.env.REACT_APP_MLOGIN_DOMAIN;
    }else{
      window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;
    }
  };

  render() {
    const { form, actions } = this.props;
    const { getFieldDecorator } = form;
    const {
      validLink,
      loading,
      subLoading,
      username,
      usernum,
      validate
    } = this.state;

    const footer = validLink ? (
      <Button type="primary" onClick={this.submit} loading={subLoading}>
        确定
      </Button>
    ) : (
      <Button type="primary" onClick={this.rtLogin}>
        返回至登录
      </Button>
    );

    return (
      <React.Fragment>
        <Header />
        <MainContent
          header="重置密码"
          footer={footer}
          className="reset-container"
        >
          <Spin spinning={loading}>
            {validLink ? (
              <PwdContent
                key={this.key}
                type={0}
                form={form}
                username={username}
                usernum={usernum}
                validate={validate}
                onChangeValidate={() => {
                  if (validate) {
                    this.setState({ validate: false }, () => {
                      form.validateFields(["confirm"], { force: true });
                    });
                  }
                }}
              />
            ) : (
              <LinkInvalid />
            )}
          </Spin>
        </MainContent>
        <Footer />
      </React.Fragment>
    );
  }
}
