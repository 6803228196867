import React, {Component} from "react";
import {connect} from "react-redux";
import {render} from "react-dom";
import {Button} from "icsoc-antd-ex";
import {Form} from "antd";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
// 主题内容
import FindPwdCont from "./findPwdCont";


import "./index.less";


export default class FindPwd extends Component {

  render() {
    return (
      <React.Fragment onSubmit={this.handleSubmit}>
        <Header/>
        <FindPwdCont/>
        <Footer/>
      </React.Fragment>
    );
  }
}

FindPwd = Form.create()(FindPwd);
// export default connect()(FindPwd);