// 填写账号
import React, {Component} from "react";
import {connect} from "react-redux";
import {render} from "react-dom";
import {
  Form,
  Input,
  Button,
  Col,
  Icon,
  message
} from "antd";
import MainContent from "@/components/MainContent";
import {storage} from "../../utils";
import {getVerify, getEmail, sendEmail} from "@/actions/findPwdActions";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {span: 5},
  wrapperCol: {span: 18}
};

class FindPwdCont extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isSpin: true,  // 是否有旋转动画
      step: 1,  // 1:填写账号  2:填写邮箱  3:发送成功  4:发送失败
      emailVerifyState: false,  // 邮箱验证码默认不显示
      sendAuthCode: true, // 控制显示‘倒计时’还是‘重新发送’
      auth_time: '', // 倒计时 计数器 默认60s
      userParams: {  // 入参相关信息
        vcc_code: '', // 企业账号
        user_num: '',  // 坐席工号
        uuid: '',  // 用户uuid
        verify: '',  // 验证码
        email: '',  // 邮箱
      },
      verifyRule: {  // 验证规则状态
        codeState: true,
        accountState: true,
        emailState: true,
        codeMsg: '',
        accountMsg: '',
        emailMsg: '',
        accountToast: '',
        emailToast: '',
      },
      userInfo: {  // 输入邮箱用户相关信息
        name: '',
        eMail: ''
      },
    };
  }

  // 倒计时
  getAuthCode = () => {
    let me = this,
      {auth_time} = me.state,
      times = auth_time;
    if (me.authTimeTimer) {
      clearTimeout(me.authTimeTimer);
    }
    me.authTimeTimer = setTimeout(() => {
      times -= 1;
      me.setState({
        auth_time: times
      })
      if (times < 1) {
        me.setState({
          sendAuthCode: false
        })
        clearTimeout(me.authTimeTimer);
      } else {
        me.getAuthCode();
      }
    }, 1000);
  };

  // 获取验证码
  vertify = () => {
    let me = this,
      {isSpin, userParams} = me.state;
    if (isSpin) {
      let params = {
        uuid: userParams.uuid
      }
      me.props.dispatch(getVerify(params, res => {
        if (res) {
          me.setState({
            isSpin: false,
            userParams: {
              ...userParams,
              uuid: res.data.uuid
            }
          })
          document.getElementById('vertifyImg').setAttribute('src', res.data.image);
        }
      }));
    }
  };

  // 验证码刷新
  verifyFresh = (cb) => {
    let me = this;
    me.setState({
      isSpin: true
    }, function () {
      if(cb =='refresh'){
        me.vertify();
      }
    })
  }

// 账号change
  accountChange = () => {
    let me = this,
      {verifyRule} = me.state;
    me.setState({
      verifyRule: {
        ...verifyRule,
        accountState: true,
        accountToast: '',
      }
    })
  }

  // 验证码change
  codeChange = () => {
    let me = this,
      {verifyRule} = me.state;
    me.setState({
      verifyRule: {
        ...verifyRule,
        codeState: true,
      }
    })
  }

  // 邮箱change
  emailChange = () => {
    let me = this,
      {verifyRule} = me.state;
    me.setState({
      verifyRule: {
        ...verifyRule,
        emailState: true,
        emailToast:''
      }
    })
  }

  // 账号规则
  accountHandleValidator = (rule, val, callback) => {
    let me = this;
    if (!val) {
      callback();
    }
    me.setState({}, function () {
      let {verifyRule} = me.state,
        validateResult = verifyRule.accountState;  // 自定义规则
      if (!validateResult) {
        callback(verifyRule.accountMsg);
      }
      callback();
    })
  }

  // 验证码规则
  verifyHandleValidator = (rule, val, callback) => {
    let me = this;
    if (!val) {
      callback();
    }
    me.setState({}, function () {
      let {verifyRule} = me.state,
        validateResult = verifyRule.codeState;  // 自定义规则
      if (!validateResult) {
        callback(verifyRule.codeMsg);
      }
      callback();
    })
  }

  // 邮箱规则
  emailHandleValidator = (rule, val, callback) => {
    let me = this;
    if (!val) {
      callback();
    }
    me.setState({}, function () {
      let {verifyRule} = me.state,
        validateResult = verifyRule.emailState;  // 自定义规则
      if (!validateResult) {
        callback(verifyRule.emailMsg);
      }
      callback();
    })
  }

  // 获取邮箱
  getEmailFun = () => {
    let me = this,
      {userParams, verifyRule} = me.state;
    let getParams = {
      ...userParams,
    }
    me.props.dispatch(getEmail(getParams, res => {
      console.log('getEmail-res', res)
      if (res) {
        switch (res.code) {
          case 2000:
            me.setState({
              verifyRule: {
                ...verifyRule,
                accountState: false,
                accountMsg: '',
                accountToast: res.message,
              }
            })
            // 企业账号或坐席工号错误/未绑定邮箱，toast提示：该坐席工号未绑定邮箱，请联系管理员
            message.error(res.message);
            me.props.form.validateFields(["vcc_code", "user_num"], {force: true});
            // 验证码刷新
            me.verifyFresh();
            break;
          case 2006:
            // 验证码错误
            me.setState({
              verifyRule: {
                ...verifyRule,
                codeState: false,
                codeMsg: res.message
              }
            })
            me.props.form.validateFields(["verify"], {force: true});
            // 验证码刷新
            me.verifyFresh();
            break;
          case 0:
            me.setState({
              userInfo: {
                name: res.data.username,
                eMail: res.data.email
              },
              step: 2,
              emailVerifyState: res.data.need_verify,
              isSpin: true
            },function () {
              if (me.state.emailVerifyState) {
                this.props.form.setFieldsValue({
                  verify: '',
                });
                // 验证码
                me.vertify();
              }
            })
            break;
          default:
            message.error(res.message);
            // 验证码刷新
            me.verifyFresh();
        }
      }
    }));
  }

  // 邮件发送
  sendEmailFun = () => {
    let me = this,
      {userParams, verifyRule, step} = me.state,
      sendParams = {
        email: userParams.email,
        uuid: userParams.uuid,
        verify: userParams.verify
      };

    let origin = window.location.origin;
    if(origin.indexOf("account-admin") != -1){
      sendParams.pageType = "account-admin";
    }else{
      sendParams.pageType = "account";
    }

    if (step == 3 || step == 4) {
      sendParams = {
        ...sendParams,
        resend: 1,
      }
    }
    me.props.dispatch(sendEmail(sendParams, res => {
      if (res) {
        switch (res.code) {
          case 2000:
            // 邮箱错误
            me.setState({
              verifyRule: {
                ...verifyRule,
                emailState: false,
                emailMsg: '',
                emailToast: res.message
              }
            })
            // 输入邮箱与绑定邮箱不匹配，toast提示：邮箱错误
            message.error(res.message);
            me.props.form.validateFields(["email"], {force: true});
            if (me.state.emailVerifyState) {
              // 验证码刷新
              me.verifyFresh('refresh');
            }
            break;
          case 2004:
            // 需要验证码
            me.setState({
              emailVerifyState: true,
              verifyRule: {
                ...verifyRule,
                valState: false,
                codeMsg: res.message,
                emailState: false,
                emailMsg: '',
                emailToast: "邮箱错误"
              }
            })
            me.props.form.validateFields(["email"], {force: true});

            // toast提示：邮箱错误
            message.error("邮箱错误");
            if (me.state.emailVerifyState) {
              // 验证码
              me.vertify();
            }
            break;
            case 2006:
            // 验证码错误
            me.setState({
              verifyRule: {
                ...verifyRule,
                codeState: false,
                codeMsg: res.message
              }
            })
            me.props.form.validateFields(["verify"], {force: true});
            if (me.state.emailVerifyState) {
              // 验证码刷新
              me.verifyFresh('refresh');
            }
            break;
          case 2005:
            // 超出最大输入次数 10，toast提示
            message.error(res.message);
            if (me.state.emailVerifyState) {
              // 验证码刷新
              me.verifyFresh('refresh');
            }
            break;
          case 0:
            // 发送成功
            me.setState({
              step: 3,
              auth_time: "60",
              sendAuthCode: true
            }, function () {
              me.getAuthCode();
            })
            break;
          default:
            // 发送失败
            me.setState({
              step: 4,
            })
        }
      }
    }));
  };

  // 邮箱格式校验规则
  emailRule = (value)=>{
    let emailReg= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return typeof (value) === 'string' && !!value.match(emailReg) && value.length < 255;
  };

  // 提交提示
  handleSubmit = e => {
    let me = this,
      {step, userParams, verifyRule} = me.state;
    e.preventDefault();
    me.props.form.validateFields((err, values) => {
      console.log('Received values of form: ', err, values);

      // 企业账号或坐席工号错误，toast提示
      if(step == 1 && !verifyRule.accountState && err && err.user_num && err.vcc_code){
        message.error(verifyRule.accountToast);
      }

      // 邮箱错误，toast提示
      if(step == 2 && !verifyRule.emailState && err && err.email && me.emailRule(values.email)){
        message.error(verifyRule.emailToast);
      }

      if (!err) {
        switch (step) {
          case 1:
            // 填写账户信息
            me.setState({
              userParams: {  // 用户入参相关信息
                ...userParams,
                vcc_code: values.vcc_code,
                user_num: values.user_num,
                verify: values.verify,
              },
            }, function () {
              me.getEmailFun();
            })
            break;
          case 2:
            // 输入邮箱
            if(me.emailRule(values.email)){
              me.setState({
                userParams: {
                  ...userParams,
                  email: values.email,
                  verify: values.verify,
                },
              }, function () {
                me.sendEmailFun();
              })
            }else{
              me.setState({
                verifyRule: {
                  ...verifyRule,
                  emailState: false,
                  emailMsg: '请输入正确的邮箱格式',
                }
              },function () {
                me.props.form.validateFields(["email"], {force: true});
              })
            }
            break;
          case 3:
            // 发送成功
            me.sendEmailFun();
            break;
          case 4:
            // 发送失败
            me.sendEmailFun();
            break;
          default:
        }
      }
    });
  };

  // 返回登录
  backToLogin = () => {
    let origin = window.location.origin;
    if(origin.indexOf("account-admin") != -1){
      // 域名包含account-admin，Mange端
      window.location.href = process.env.REACT_APP_MLOGIN_DOMAIN;
    }else{
      window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;
    }
  }

  // dom
  mainDom = () => {
    let me = this,
      {isSpin, step, userInfo, sendAuthCode, auth_time, emailVerifyState} = me.state,
      {getFieldDecorator} = me.props.form;

    let NewBtnText = {
        left: "",
        right: "",
        lDis: false,
        rDis: false,
        submitClassName:'',
      },
      dom = {
        main: '',
        footer: ''
      };
    switch (step) {
      case 1:
        // 填写校验信息

        // 获取验证码
        me.vertify();

        // 获取默认企业账号、坐席工号
        let vcc_code = storage.getItem("vcc_code") ? storage.getItem("vcc_code") : "",
          user_num = storage.getItem("user_num") ? storage.getItem("user_num") : "";

        dom.main = (<React.Fragment>
          <FormItem label="企业账号：" {...formItemLayout}>
            {getFieldDecorator('vcc_code', {
              initialValue: vcc_code,
              rules: [
                {
                  required: true,
                  message: '请输入企业账号',
                }, {
                  validator: me.accountHandleValidator
                }
              ],
            })(<Input placeholder="请输入企业账号" onChange={me.accountChange}/>)}
          </FormItem>
          <FormItem label="坐席工号：" {...formItemLayout}>
            {getFieldDecorator('user_num', {
              initialValue: user_num,
              rules: [
                {
                  required: true,
                  message: '请输入坐席工号',
                }, {
                  validator: me.accountHandleValidator
                }
              ],
            })(<Input placeholder="请输入坐席工号" onChange={me.accountChange}/>)}
          </FormItem>
          <FormItem label="验证码：" {...formItemLayout}>
            <Col span={12}>
              {getFieldDecorator('verify', {
                rules: [
                  {
                    required: true, message: '请输入验证码'
                  }, {
                    validator: me.verifyHandleValidator
                  }
                ],
              })(<Input placeholder="请输入验证码" onChange={me.codeChange}/>
              )}
            </Col>
            <Col span={10}>
              <img id={"vertifyImg"} className="vertify-img" src="" alt=""/>
            </Col>
            <Col span={2}>
              <a className="icon iconfont " onClick={me.verifyFresh}>
                <Icon type="sync" style={{fontSize: '16px', color: '#999'}} spin={isSpin}/>
              </a>
            </Col>
          </FormItem>
        </React.Fragment>);
        NewBtnText = {
          left: "下一步",
          right: "取消"
        }
        break;
      case 2:
        // 填写邮箱
        dom.main = (<React.Fragment>
          <div className={"user-tips"}>尊敬的：{userInfo.name}，您正在找回密码，请输入您绑定的邮箱的完整地址！</div>
          <div className={"user-email-tips"}>您绑定的邮箱为：{userInfo.eMail}</div>
          <FormItem label="邮箱：" {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: '请填写邮箱',
                },
                {
                  validator: me.emailHandleValidator
                }
              ],
            })(<Input placeholder="请填写邮箱" onChange={me.emailChange}/>)}
          </FormItem>
          {emailVerifyState ? <FormItem label="验证码：" {...formItemLayout}>
            <Col span={12}>
              {getFieldDecorator('verify', {
                rules: [
                  {
                    required: true, message: '请输入验证码'
                  }, {
                    validator: me.verifyHandleValidator
                  }
                ],
              })(<Input placeholder="请输入验证码" onChange={me.codeChange}/>
              )}
            </Col>
            <Col span={10}>
              <img id={"vertifyImg"} className="vertify-img" src="" alt=""/>
            </Col>
            <Col span={2}>
              <a className="icon iconfont " onClick={me.verifyFresh.bind(this,'refresh')}>
                <Icon type="sync" style={{fontSize: '16px', color: '#999'}} spin={isSpin}/>
              </a>
            </Col>
          </FormItem> : ''}
        </React.Fragment>);
        NewBtnText = {
          left: "确定",
          right: "取消"
        }
        break;
      case 3:
        // 发送邮件成功
        dom.main = (<div className={"send-mail-success"}>
          <div className={"icon"}>
            <i className="iconfont iconcheck-fill"/>
          </div>
          <div className={"tips"}>发送成功！</div>
          <div className={"tips2"}>重置密码链接已经生成并通过邮件发送给你。</div>
          <div className={"tips2"}>如果没有收到邮件，请联系管理员！</div>
        </div>);
        NewBtnText = {
          left: sendAuthCode ? auth_time + "s后可重新发送" : "重新发送",
          right: "返回至登录",
          lDis: sendAuthCode ? true : false,
          submitClassName: 're-send-btn',
        }
        break;
      case 4:
        // 发送邮件失败
        dom.main = (<div className={"send-mail-fail"}>
          <div className={"icon"}>
            <i className="iconfont iconclose-fill"></i>
          </div>
          <div className={"tips"}>发送失败！</div>
          <div className={"tips2"}>邮件发送失败，请联系管理员！</div>
        </div>);
        NewBtnText = {
          left: "重新发送",
          right: "返回至登录",
        }
        break;
      default:
    }
    dom.footer = (<React.Fragment>
      <Button type="primary" htmlType="submit" className={`findpwd-btn ${NewBtnText.submitClassName}`}
              disabled={NewBtnText.lDis}>{NewBtnText.left}</Button>
      <Button type="default" className={"findpwd-btn"} disabled={NewBtnText.rDis}
              onClick={me.backToLogin}>{NewBtnText.right}</Button>
    </React.Fragment>);

    return dom;
  }


  render() {
    const me = this,
      mainCont = me.mainDom();
    return (
      <React.Fragment>
        <Form onSubmit={me.handleSubmit} className="login-form">
          <MainContent header="找回密码" className={"findpwd-cont"} footer={mainCont.footer}>
            {mainCont.main}
          </MainContent>
        </Form>
      </React.Fragment>
    );
  }
}

FindPwdCont = Form.create()(FindPwdCont);
export default connect()(FindPwdCont);
